export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'articles',
      displayName: 'menu.articles',
      meta: {
        icon: 'article',
      },
    },
    {
      name: 'institutions',
      displayName: 'menu.institutions',
      meta: {
        icon: 'school',
      },
    },
    {
      name: 'workflow',
      displayName: 'menu.workflow',
      meta: {
        icon: 'work',
      },
      children: [
        {
          name: 'workflow_reviews',
          displayName: 'menu.reviews',
        },
      ],
    },
    {
      name: 'directory',
      displayName: 'menu.directory',
      meta: {
        icon: 'folder',
      },
      children: [
        {
          name: 'programs',
          displayName: 'menu.programs',
        },
      ],
    },
    {
      name: 'reviews',
      displayName: 'menu.reviews',
      meta: {
        icon: 'reviews',
      },
    },
  ] as INavigationRoute[],
}
